export type CardData = {
    img: string
    title: string
}

const cards: CardData[] = [
    {
        img: '/cards/breathe_or_break.jpeg',
        title: 'Breathe or break',
    },
    {
        img: '/cards/do_not_panic.jpeg',
        title: 'Do not panic!'
    },
    {
        img: '/cards/dont_waste_potential.jpeg',
        title: 'Don\'t waste potential'
    },
    {
        img: '/cards/have_a_plan.jpeg',
        title: 'Have a plan'
    },
    {
        img: '/cards/impact_on_field.jpeg',
        title: 'Impact on field'
    },
    {
        img: '/cards/it_might_be_your_fault.jpeg',
        title: 'It might be your fault'
    },
    {
        img: '/cards/loosen_up.jpeg',
        title: 'Loosen up'
    },
    {
        img: '/cards/no_blind_pressure.jpeg',
        title: 'No blind pressure'
    },
    {
        img: '/cards/no_distractions.jpeg',
        title: 'No distractions'
    },
    {
        img: '/cards/plats_be_plattin.jpeg',
        title: 'Plats be plattin\''
    },
    {
        img: '/cards/solo.jpeg',
        title: 'Play solo'
    },
    {
        img: '/cards/use_your_eyes.jpeg',
        title: 'Use your eyes'
    },
    {
        img: '/cards/war_of_attrition.jpeg',
        title: 'War of attrition'
    },
]

export default cards
