import * as React from 'react'
// import Slideshow from './Slideshow'
import Grid from './Grid'

const Content = () => {
    return <div>
        <Grid />
        {/*<Slideshow />*/}
    </div>
}


export default Content
