import * as React from 'react'
import cards from '../data/cards'
import './Grid.scss'

const Grid = () => {
    return <div className="Grid">
        {cards.map(card => {
            return (
                <img
                    className="tile"
                    key={card.img}
                    src={card.img}
                    alt={card.title}
                />
            )
        })}
    </div>
}


export default Grid
